
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { EMPTY, PROVIDER, SERVICES as S } from "@/config/literals";

import {
  CalibrationBlockCategory,
  CalibrationBlockItem,
  LITE_FEATURE,
  StepCalibrationBlockItem,
  V1CalibrationBlockItem,
  V2CalibrationBlockItem,
} from "@/domain";
import { ServerType } from "prometheus-synced-ui";

import TableUi from "@/components/Core/Table/TableUI.vue";

import {
  IStepCalibrationBlockService,
  IV1CalibrationBlockService,
  IV2CalibrationBlockService,
} from "@/services";

@Component({
  data: () => {
    return {
      EMPTY,
      CalibrationBlockCategory,
    };
  },
  filters: {
    prettyCategory(category: CalibrationBlockCategory): string {
      switch (category) {
        case CalibrationBlockCategory.v1: {
          return "V1";
        }
        case CalibrationBlockCategory.v2: {
          return "V2";
        }
        case CalibrationBlockCategory.step: {
          return "Gradins";
        }
        case CalibrationBlockCategory.unknown: {
          return EMPTY;
        }
      }
    },
    prettyDimensions(item: CalibrationBlockItem) {
      if (item.blockCategory == CalibrationBlockCategory.unknown) {
        return EMPTY;
      } else if (item.blockCategory == CalibrationBlockCategory.v2) {
        return (
          (item.dimensionValue != null && `${item.dimensionValue} mm`) || EMPTY
        );
      } else {
        return (
          (item.minDimension != null &&
            item.maxDimension != null &&
            `${item.minDimension} à ${item.maxDimension} mm`) ||
          EMPTY
        );
      }
    },
  },
  components: {
    TableUi,
  },
})
export default class CalibrationBlocksTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get v1CalibrationBlockService() {
    return this.container.resolve<IV1CalibrationBlockService>(
      S.V1_CALIBRATION_BLOCK_SERVICE
    );
  }

  get v2CalibrationBlockService() {
    return this.container.resolve<IV2CalibrationBlockService>(
      S.V2_CALIBRATION_BLOCK_SERVICE
    );
  }

  get stepCalibrationBlockService() {
    return this.container.resolve<IStepCalibrationBlockService>(
      S.STEP_CALIBRATION_BLOCK_SERVICE
    );
  }

  get existingSerialNumbers() {
    return _.map(this.allCalibrationBlocks, (b) => {
      return { value: b.serialNumber, category: b.blockCategory };
    });
  }

  get createComponent() {
    return () =>
      import(
        "@/components/Core/Create/Equipments/CalibrationBlocks/CalibrationBlockCreate.vue"
      );
  }

  get updateComponent() {
    return () =>
      import(
        "@/components/Core/Update/Equipments/CalibrationBlocks/CalibrationBlockUpdate.vue"
      );
  }

  get allCalibrationBlocks() {
    return _.union(
      _.map(this.v1Blocks, (b) => CalibrationBlockItem.fromV1(b)),
      _.map(this.v2Blocks, (b) => CalibrationBlockItem.fromV2(b)),
      _.map(this.stepBlocks, (b) => CalibrationBlockItem.fromStep(b))
    );
  }

  get headers() {
    return [
      {
        text: "Type de cale",
        align: "center",
        sortable: true,
        value: "blockCategory",
      },
      {
        text: "Numéro de série",
        align: "center",
        sortable: true,
        value: "serialNumber",
      },
      {
        text: "Gamme",
        align: "center",
        sortable: true,
        value: "dimensions",
      },
      {
        text: "Nuance",
        align: "center",
        sortable: true,
        value: "materialShade.shadeValue",
      },
      {
        text: "Célérité (m/s)",
        align: "center",
        sortable: true,
        value: "celerity",
      },
      {
        text: "Numéro de PV",
        align: "center",
        sortable: true,
        value: "pvNumber",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  v1Blocks: V1CalibrationBlockItem[] = [];
  v2Blocks: V2CalibrationBlockItem[] = [];
  stepBlocks: StepCalibrationBlockItem[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.v1Blocks = await this.v1CalibrationBlockService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });

      this.v2Blocks = await this.v2CalibrationBlockService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });

      this.stepBlocks = await this.stepCalibrationBlockService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(value: { block: any; category: CalibrationBlockCategory }) {
    switch (value.category) {
      case CalibrationBlockCategory.v1: {
        this.v1Blocks.push(value.block);
        break;
      }
      case CalibrationBlockCategory.v2: {
        this.v2Blocks.push(value.block);
        break;
      }
      case CalibrationBlockCategory.step: {
        this.stepBlocks.push(value.block);
        break;
      }
    }
  }

  update(value: { block: any; category: CalibrationBlockCategory }) {
    switch (value.category) {
      case CalibrationBlockCategory.v1: {
        const v1Index = _.findIndex(
          this.v1Blocks,
          (b) => b.id == value.block.id
        );
        if (v1Index != -1) {
          this.v1Blocks.splice(v1Index, 1, value.block);
        }
        break;
      }
      case CalibrationBlockCategory.v2: {
        const v2Index = _.findIndex(
          this.v2Blocks,
          (b) => b.id == value.block.id
        );
        if (v2Index != -1) {
          this.v2Blocks.splice(v2Index, 1, value.block);
        }
        break;
      }
      case CalibrationBlockCategory.step: {
        const stepIndex = _.findIndex(
          this.stepBlocks,
          (b) => b.id == value.block.id
        );
        if (stepIndex != -1) {
          this.stepBlocks.splice(stepIndex, 1, value.block);
        }
        break;
      }
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const block = _.find(this.allCalibrationBlocks, (b) => b.id == id)!;
      switch (block.blockCategory) {
        case CalibrationBlockCategory.v1: {
          const updatedV1Block = await this.v1CalibrationBlockService.update(
            id,
            {
              serialNumber: block.serialNumber,
              minDimension: block.minDimension!,
              maxDimension: block.maxDimension!,
              materialShadeId: block.materialShade.id,
              celerity: block.celerity,
              pvNumber: block.pvNumber,
              disabled: !block.disabled,
            }
          );
          this.update({
            block: V1CalibrationBlockItem.fromDetails(updatedV1Block),
            category: CalibrationBlockCategory.v1,
          });
          break;
        }
        case CalibrationBlockCategory.v2: {
          const updatedV2Block = await this.v2CalibrationBlockService.update(
            id,
            {
              serialNumber: block.serialNumber,
              dimensionValue: block.dimensionValue!,
              materialShadeId: block.materialShade.id,
              celerity: block.celerity,
              pvNumber: block.pvNumber,
              disabled: !block.disabled,
            }
          );
          this.update({
            block: V2CalibrationBlockItem.fromDetails(updatedV2Block),
            category: CalibrationBlockCategory.v2,
          });
          break;
        }
        case CalibrationBlockCategory.step: {
          const updatedStepBlock =
            await this.stepCalibrationBlockService.update(id, {
              serialNumber: block.serialNumber,
              minDimension: block.minDimension!,
              maxDimension: block.maxDimension!,
              materialShadeId: block.materialShade.id,
              celerity: block.celerity,
              pvNumber: block.pvNumber,
              disabled: !block.disabled,
            });
          this.update({
            block: StepCalibrationBlockItem.fromDetails(updatedStepBlock),
            category: CalibrationBlockCategory.step,
          });
          break;
        }
      }
    } finally {
      this.loading = false;
    }
  }
}
