
import { Component, Vue } from "vue-property-decorator";

import MaterialShadesTable from "@/components/Core/Table/Equipments/MaterialShadesTable.vue";
import CalibrationBlocksTable from "@/components/Core/Table/Equipments/CalibrationBlocksTable.vue";

@Component({
  components: {
    MaterialShadesTable,
    CalibrationBlocksTable,
  },
})
export default class CalibrationBlockMenu extends Vue {}
