
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { ServerType } from "prometheus-synced-ui";
import {
  LITE_FEATURE,
  MaterialShadeDetails,
  MaterialShadeInfos,
} from "@/domain";

import { IMaterialShadeService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class MaterialShadesTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get materialShadeService() {
    return this.container.resolve<IMaterialShadeService>(
      S.MATERIAL_SHADE_SERVICE
    );
  }

  get createComponent() {
    return () =>
      import("@/components/Core/Create/Equipments/MaterialShadeCreate.vue");
  }

  get updateComponent() {
    return () =>
      import("@/components/Core/Update/Equipments/MaterialShadeUpdate.vue");
  }

  get existingValues() {
    return _.map(this.shades, (s) => s.shadeValue);
  }

  get headers() {
    return [
      {
        text: "Nom",
        align: "center",
        sortable: true,
        value: "shadeValue",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  shades: MaterialShadeInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.shades = await this.materialShadeService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(shade: MaterialShadeDetails) {
    this.shades.push(MaterialShadeInfos.fromDetails(shade));
  }

  update(shade: MaterialShadeDetails) {
    const index = _.findIndex(this.shades, (s) => s.id == shade.id);
    if (index != -1) {
      this.shades.splice(index, 1, shade);
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const shade = _.find(this.shades, (s) => s.id == id)!;
      const result = await this.materialShadeService.update(id, {
        shadeValue: shade.shadeValue!,
        disabled: !shade.disabled,
      });
      this.update(result);
    } finally {
      this.loading = false;
    }
  }
}
